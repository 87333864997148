<script setup lang="ts">
import type { Agency, JobPosting, WebPage, File } from '~/types/api'
import { getStructuredDataScript } from '~/utils/structured-data'

interface VWebPageStructuredDataProps {
    webPage?: WebPage
    agency?: Agency
    jobPosting?: JobPosting
    title?: string
    image?: string | File
    type?: string
}

const props = defineProps<VWebPageStructuredDataProps>()

const runtimeConfig = useRuntimeConfig()
const { $canonicalUrl } = useNuxtApp()
const outputTitle = computed(() => {
    return (
        props.title ||
        props.webPage?.title ||
        props.agency?.title ||
        props.jobPosting?.title ||
        runtimeConfig.public.siteName
    )
})

const nuxtImg = useImage()

const img = computed(() => {
    const apiImgPath = props.webPage?.image?.relativePath || (props.image as File)?.relativePath
    const staticImgPath = typeof props.image === 'string' ? props.image : undefined

    const provider = staticImgPath ? 'ipx' : 'interventionRequest'

    if (!(apiImgPath && staticImgPath)) return

    return nuxtImg(apiImgPath || staticImgPath, { width: 1200 }, { provider })
})

const structuredDataScript = computed(() => {
    return getStructuredDataScript({
        '@context': 'https://schema.org',
        '@type': props.type || 'WebPage',
        name: outputTitle.value,
        image: img.value,
        url: $canonicalUrl.value,
    })
})
</script>

<template>
    <component :is="structuredDataScript" />
</template>
